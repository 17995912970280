import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../services/products.service';

@Component({
  selector: 'app-tokenomics',
  templateUrl: './tokenomics.component.html',
  styleUrls: ['./tokenomics.component.css']
})
export class TokenomicsComponent implements OnInit {
  supertest: any = "supperteest"
  slickCounter: any = 0
  tokenNumber: any = 21000000
  constructor(

    public productservice: ProductsService) {

  }
  slides = [
    { img: "http://placehold.it/350x150/000000" },
    { img: "http://placehold.it/350x150/111111" },
    { img: "http://placehold.it/350x150/333333" },
    { img: "http://placehold.it/350x150/666666" },
    { img: "http://placehold.it/350x150/111111" },
    { img: "http://placehold.it/350x150/333333" },
    { img: "http://placehold.it/350x150/666666" },
    { img: "http://placehold.it/350x150/111111" },
    { img: "http://placehold.it/350x150/333333" },
    { img: "http://placehold.it/350x150/666666" }
  ];
  slideConfig = {
    "slidesToShow": 8, "slidesToScroll": 8, "autoplaySpeed": 300, responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true
        }
      }, {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true
        }
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };

  addSlide() {
    this.slides.push({ img: "http://placehold.it/350x150/777777" })
  }

  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }

  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {
    console.log('afterChange');
  }

  beforeChange(e) {
    console.log('beforeChange');
  }

  ajouterSlick(){
    if(this.slickCounter >= 140){
      this.slickCounter = 140
      return 140
    } else {
      this.slickCounter = this.slickCounter + 10
      return this.slickCounter
    }
  }

  revenirSlick(){
    if(this.slickCounter <= 1){
      this.slickCounter = 1
      return 1
    } else {
      this.slickCounter = this.slickCounter - 10
      return this.slickCounter
    }
  }




  ngOnInit() {
    this.productservice.phasesPricesGet()





    
  }

  ngOnDestroy(){
    this.productservice.phasesPrices = []
  }


}
